import React, { useState, useEffect } from "react";
import { useUIText } from "../hooks";
import TickCircle from "../assets/tick-circle.js";
import XCircle from "../assets/x-circle.js";
import SAicon from "../assets/sa-icon";
import { getBrightness } from "../utils";
import { ReactComponent as FileIcon } from "../assets/file-demo.svg";
import { ReactComponent as ConnectIcon } from "../assets/connect-demo.svg";
import { ReactComponent as UploadIcon } from "../assets/upload-demo.svg";

const ConversationFeedback = ({
  borderColor,
  onClick,
  resetFeedback,
  loading,
  onBackButtonClick,
  isDemo = false,
  avatarImg,
  avatarName,
}) => {
  const [feedbackGiven, setFeedbackGiven] = useState(false);
  const [conversationFeedback, setConversationFeedback] = useState(null);
  const [backBtnHovered, setBackBtnHovered] = useState(false);

  const uiText = useUIText();

  useEffect(() => {
    if (resetFeedback) {
      setFeedbackGiven(false);
      setConversationFeedback(null);
    }
  }, [resetFeedback]);

  const handleNoClick = () => {
    onClick(false);
    setFeedbackGiven(true);
    setConversationFeedback(false);
  };
  const handleYesClick = () => {
    onClick(true);
    setFeedbackGiven(true);
    setConversationFeedback(true);
  };

  // Scroll when feedback is given
  useEffect(() => {
    if (isDemo && feedbackGiven) {
      window.scrollBy({ top: 150, behavior: "smooth" });
    }
  }, [feedbackGiven, isDemo]);

  return isDemo ? (
		<div
			className="con-feedback-wrapper"
			style={{
				borderColor: borderColor,
				opacity: loading ? 0 : 1,
				pointerEvents: "auto",
				transition: "opacity 0.5s ease, visibility 0.5s ease",
				visibility: loading ? "hidden" : "visible",
			}}
		>
			<div className="assistant-avatar" style={{ paddingBottom: "1rem" }}>
				{avatarImg ? (
					<img
						src={avatarImg}
						alt="avatar-img"
						style={{ maxWidth: "40px", maxHeight: "40px" }}
					/>
				) : (
					<SAicon
						circleColor={borderColor}
						color={getBrightness(borderColor) > 155 ? "black" : "white"}
					/>
				)}

				<span>{avatarName ?? "Service Assistant AI"}</span>
			</div>
			<div
				className="con-feedback-container"
				style={{
					borderColor: borderColor,
					opacity: loading ? 0 : feedbackGiven && !loading ? 0.5 : 1,
					pointerEvents: feedbackGiven ? "none" : "auto",
					transition: "opacity 0.5s ease, visibility 0.5s ease",
					visibility: loading ? "hidden" : "visible",
				}}
			>
				<div className="con-feedback-container__title">
					{uiText.conversationFeedback.title}
				</div>
				<div className="con-feedback-container__feedbacks">
					<div
						className="con-feedback-container__feedback feedback-yes"
						onClick={handleYesClick}
					>
						<div>
							<TickCircle color="#495B84" />
						</div>
						<span
							style={{
								color: "#495B84",
							}}
						>
							{uiText.conversationFeedback.yes}
						</span>
					</div>
					<div
						className="con-feedback-container__feedback feedback-no"
						onClick={handleNoClick}
					>
						<div>
							<XCircle color="#495B84" />
						</div>
						<span>{uiText.conversationFeedback.no}</span>
					</div>
				</div>
			</div>
			{conversationFeedback ? (
				<div className="con-feedback-container" style={{ marginTop: "1rem" }}>
					<div className="con-feedback-container__yes">
						<div>
							<div className="con-feedback-container__yes-title">
								Implement <span>Service Assistant AI</span> to your website.
							</div>
							<div className="con-feedback-container__yes-text">
								Integrating service assistant with your website or mobile app is
								easy. Let’s talk about how it can serve your use case!
							</div>
						</div>
						<a
							href="https://service-assistant.ai/demo-calendly"
							target="_parent"
						>
							<button className="continue-button">Continue</button>
						</a>
					</div>
				</div>
			) : feedbackGiven ? (
				<div className="con-feedback-container" style={{ marginTop: "2rem" }}>
					<div className="con-feedback-container__no-title--big">
						Upload more data to train <span>Service Assistant AI</span>
					</div>
					<div className="con-feedback-container__no">
						<div style={{ flex: 1 }} className="first-div">
							<div className="con-feedback-container__no-title">
								Our AI learns on your documentation, and data from your CRM or
								CMS.
							</div>
							<div className="con-feedback-container__no-text">
								In order to provide better answers our AI model needs more
								information about your products and services. We support files
								in any format and can integrate with your existing tools. Let’s
								upload more data.
							</div>
						</div>
						<div style={{ flex: 1 }}>
							<div className="con-feedback-container__no-options">
								<div className="no-options__icons">
									<div className="no-options__icon">
										<FileIcon />
									</div>
									<div className="no-options__icon">
										<ConnectIcon />
									</div>
									<div className="no-options__icon">
										<UploadIcon />
									</div>
								</div>
								<a
									href="https://service-assistant.ai/demo-calendly"
									target="_parent"
								>
									<button
										className="continue-button"
										style={{ marginTop: "unset", marginLeft: "unset" }}
									>
										Continue
									</button>
								</a>
							</div>
						</div>
					</div>
				</div>
			) : null}
		</div>
	) : (
		<div
			className="con-feedback-wrapper"
			style={{
				borderColor: borderColor,
				opacity: loading
					? 0
					: feedbackGiven && !loading && !conversationFeedback
					? 0.5
					: 1,
				pointerEvents: feedbackGiven && !conversationFeedback ? "none" : "auto",
				transition: "opacity 0.5s ease, visibility 0.5s ease",
				visibility: loading ? "hidden" : "visible",
			}}
		>
			<div className="assistant-avatar" style={{ paddingBottom: "1rem" }}>
				{avatarImg ? (
					<img
						src={avatarImg}
						alt="avatar-img"
						style={{ maxWidth: "40px", maxHeight: "40px" }}
					/>
				) : (
					<SAicon
						circleColor={borderColor}
						color={getBrightness(borderColor) > 155 ? "black" : "white"}
					/>
				)}

				<span>{avatarName ?? "Service Assistant AI"}</span>
			</div>
			{conversationFeedback ? (
				<div className="con-feedback-container">
					<div
						className="con-feedback-container__feedbacks"
						style={{ alignItems: "center", justifyContent: "space-between" }}
					>
						<div
							className="con-feedback-container__title"
							style={{ paddingBottom: "0" }}
						>
							{uiText?.result?.feedback.thankYou}
						</div>
						<div className="back-btn">
							<button
								onClick={() => {
									onBackButtonClick();
								}}
								onMouseEnter={() => setBackBtnHovered(true)}
								onMouseLeave={() => setBackBtnHovered(false)}
								style={{
									borderColor: "transparent",
									backgroundColor: "transparent",
									color: backBtnHovered ? borderColor : "#6982A8",
									whiteSpace: "nowrap",
								}}
							>
								<span>+ {uiText?.askNewQuestion}</span>
							</button>
						</div>
					</div>
				</div>
			) : (
				<div className="con-feedback-container">
					<div className="con-feedback-container__title">
						{uiText.conversationFeedback.title}
					</div>
					<div className="con-feedback-container__feedbacks">
						<div
							className="con-feedback-container__feedback feedback-yes"
							onClick={handleYesClick}
						>
							<div>
								<TickCircle color="#495B84" />
							</div>
							<span
								style={{
									color: "#495B84",
								}}
							>
								{uiText.conversationFeedback.yes}
							</span>
						</div>
						<div
							className="con-feedback-container__feedback feedback-no"
							onClick={handleNoClick}
						>
							<div>
								<XCircle color="#495B84" />
							</div>
							<span>{uiText.conversationFeedback.no}</span>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default ConversationFeedback;
