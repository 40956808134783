import React, { useState } from "react";

const StepsComponent = ({ inputText, language, markerColor, markerTextColor }) => {
	const [fullSizeImage, setFullSizeImage] = useState(null);
	// Function to format HTML content
	function formatContent(html) {
		// Preserve <a> tags by temporarily replacing them with placeholders
		const aTagRegex = /<a href="([^"]+)">([^<]*)<\/a>/gi;
		const aTagPlaceholders = [];
		html = html.replace(aTagRegex, (match, href, text) => {
			const placeholder = `%%aTag%%${aTagPlaceholders.length}%%`;
			aTagPlaceholders.push({ href, text });
			return placeholder;
		});

		// Convert <b>...</b> tags to bold text content
		const boldTagRegex = /<b>(.*?)<\/b>/gi;
		html = html.replace(boldTagRegex, "<b>$1</b>");

		// Convert <em>...</em> tags to italic text content
		const emTagRegex = /<em>(.*?)<\/em>/gi;
		html = html.replace(emTagRegex, "<i>$1</i>");

		// Replace "arr;" with the arrow symbol, but only when it's not part of another word
		html = html.replace(/\barr;/g, "→"); // \b ensures "arr;" is matched as a word boundary

		// Keep the existing logic for handling bold and italic text outside of <b> tags
		const boldItalicRegex = /(\*\*|__)(.*?)\1/g;
		const italicRegex = /(\*|_)(.*?)\1/g;
		html = html.replace(boldItalicRegex, "<b>$2</b>");
		html = html.replace(italicRegex, "<i>$2</i>");

		// Handling warnings
		const warningRegex = /<p>(Warning:|Warnung:|Achtung:)(.*?)<\/p>/g;
		html = html.replace(warningRegex, (match, p1, p2) => {
			if (language !== "German" && p1.startsWith("Warnung: Es")) {
				return "";
			}
			return `<p><span role="img" aria-label="warning" style="margin-right: 5px;">⚠️</span> ${p1}${p2}</p>`;
		});

		// Handling steps
		const stepRegex =
			/<p>(Step|Schritt|Paso|Krok|Étape) (\d+)(?:\..*?)? *:?\s*([^0-9].*)<\/p>/gi;
		html = html.replace(stepRegex, (match, p1, p2, p3) => {
			return `
                <div class="step">
                    <div style="
                        display: inline-block;
                        width: 24px;
                        height: 24px;
                        border-radius: 50%;
                        background-color: #E3E9F2;
                        color: #000;
                        text-align: center;
                        line-height: 26px;
                        margin-right: 5px;
                        font-size: 12px;
                        font-weight: 700;
                    ">${p2}</div>
                    <span>${p3}</span>
                </div>
            `;
		});
		// Handle <img> tags for image preview
		// Handle <img> tags for image preview by adding a div wrapper with class 'img-preview'
		const imgTagRegex =
			/<img\s+src=["']([^"']+)["'](?:[^>]*alt=["']([^"']*)["'])?[^>]*>/gi;

		html = html.replace(imgTagRegex, (match, src, alt) => {
			// Wrap the image in a div with the class 'img-preview'
			const altText = alt || ""; // Use empty string if alt is undefined or null
			const altAttribute = altText.trim() !== "" ? `alt="${altText}"` : "";

			return `
    <div class="img-preview" style="cursor: zoom-in; margin: 5px auto 10px; width: fit-content" data-src="${src}">
      <img src="${src}" ${altAttribute} style="width: 100%; height: auto; max-width: 300px; margin-inline: auto; display: block"/>
    </div>
  `;
		});
		// Restore <a> tags from placeholders with target="_blank" and rel="noopener noreferrer"
		html = html.replace(/%%aTag%%(\d+)%%/g, (match, index) => {
			const { href, text } = aTagPlaceholders[Number(index)];
			return `<a href="${href}" target="_blank" rel="noopener noreferrer">${text}</a>`;
		});

		return html;
	}

	// Handle image click for full-size display
	const handleImageClick = (event) => {
		const imgElement = event.target.closest(".img-preview");
		if (imgElement) {
			const fullSizeSrc = imgElement.getAttribute("data-src");
			setFullSizeImage(fullSizeSrc);
		}
	};

	// Function to close the full-size image modal
	const closeFullSizeImage = () => {
		setFullSizeImage(null);
	};

	// Check if inputText contains HTML tags
	const containsHTML = /<\/?[a-z][\s\S]*>/i.test(inputText);

	// Format the entire input text content
	const formattedContent = formatContent(inputText);

	return (
		<div
			style={{
				"--marker-bg-color": markerColor,
				"--marker-text-color": markerTextColor,
			}}
		>
			{containsHTML ? (
				<div
					onClick={handleImageClick}
					dangerouslySetInnerHTML={{ __html: formattedContent }}
				/>
			) : (
				<div>{formattedContent}</div>
			)}

			{/* Modal for full-size image */}
			{fullSizeImage && (
				<div
					className="modal"
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						position: "fixed",
						zIndex: "100001",
						left: "0",
						top: "0",
						width: "100%",
						height: "100%",
						backgroundColor: "rgba(0,0,0,0.8)",
						opacity: "1",
						cursor: "zoom-out",
					}}
					onClick={closeFullSizeImage}
				>
					<span
						className="close"
						style={{
							position: "absolute",
							top: "20px",
							right: "40px",
							color: "white",
							fontSize: "40px",
							fontWeight: "bold",
						}}
						onClick={closeFullSizeImage}
					>
						&times;
					</span>
					<img
						src={fullSizeImage}
						alt="Full size"
						style={{ maxWidth: "90%", maxHeight: "90%" }}
					/>
				</div>
			)}
		</div>
	);
};

export default StepsComponent;
