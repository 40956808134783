import axios from "axios";

import PencilIcon from "../assets/pencil.js";
import StarIcon from "../assets/star.js";
import LightbulbIcon from "../assets/lightbulb.js";
import CompassIcon from "../assets/compass.js";
import QuestionIcon from "../assets/question.js";

export function getBrightness(color) {
  const rgb = parseInt(color.replace("#", ""), 16); // convert rrggbb to decimal
  const r = (rgb >> 16) & 0xff; // extract red
  const g = (rgb >> 8) & 0xff; // extract green
  const b = (rgb >> 0) & 0xff; // extract blue

  // Calculate brightness according to the formula: (299*R + 587*G + 114*B) / 1000
  return (299 * r + 587 * g + 114 * b) / 1000;
}

// Function to convert audio blob to base64 encoded string
export function audioBlobToBase64(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const arrayBuffer = reader.result;
      const base64Audio = btoa(
        new Uint8Array(arrayBuffer).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          ""
        )
      );
      resolve(base64Audio);
    };
    reader.onerror = reject;
    reader.readAsArrayBuffer(blob);
  });
}
export function initializeHotjarTracker() {
  if (process.env.REACT_APP_ENV !== "production") {
    return;
  }

  (function (h, o, t, j, a, r) {
    h.hj =
      h.hj ||
      function () {
        (h.hj.q = h.hj.q || []).push(arguments);
      };
    h._hjSettings = { hjid: 5191615, hjsv: 6 };
    a = o.getElementsByTagName("head")[0];
    r = o.createElement("script");
    r.async = 1;
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
    a.appendChild(r);
  })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
  window.hj("identify", process.env.REACT_APP_INSTANCE_ID, {});
}

// Helper function to replace underscores with spaces in labels
export function formatDeviceName(name) {
  return name.replace(/_/g, " ");
}
export function getApiURL() {
  switch (process.env.REACT_APP_ENV) {
    case "development": {
      return "http://localhost:9000";
    }
    case "staging": {
      return "https://admin-api.service-assistant-staging.com";
    }
    case "production": {
      return "https://admin-api.service-assistant.ai";
    }
    default: {
      return "http://localhost:9000";
    }
  }
}
export const sendQuestionData = async (
  userInput,
  answer,
  sourceDocument = "",
  sourcePages = [],
  sessionId = null,
  conversationId = null,
  error = false,
  emptyAnswer = true,
  otherQuestionsEnabled = false
) => {
  const instanceId = process.env.REACT_APP_INSTANCE_ID;

  let logUrl = `${getApiURL()}/instance/record-question`;

  if (logUrl) {
    const postData = {
      instanceId: instanceId,
      deviceName: otherQuestionsEnabled ? "general" : userInput.device,
      deviceType: otherQuestionsEnabled ? "general" : userInput.deviceType,
      manufacturer: otherQuestionsEnabled ? "general" : userInput.manufacturer,
      question: userInput.problem,
      answer: answer,
      sourceDocument,
      sourcePages: sourcePages.join(","),
      error: error,
      emptyAnswer: emptyAnswer,
    };

    if (conversationId) {
      postData.conversationId = conversationId;
    }
    if (sessionId) {
      postData.sessionId = sessionId;
    }
    try {
      const res = await axios.post(logUrl, postData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return {
        recordId: res.data.recordId,
        conversationId: res.data.conversationId,
      };
    } catch (err) {
      console.error("Failed to log question data:", err.message);
      if (err.status === 403) {
        return {
          limitReached: true,
        };
      } else {
        return null;
      }
    }
  }
  return null;
};

export const checkLimit = async () => {
  const instanceId = process.env.REACT_APP_INSTANCE_ID;
  const url = `${getApiURL()}/instance/${instanceId}/check-limit`;
  if (url) {
    const postData = {
      instanceId: instanceId,
    };
    try {
      const res = await axios.get(url, postData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return res.data.limitReached;
    } catch (err) {
      console.error("Failed to check limit:", err.message);
      return false;
    }
  }
  return false;
};

export const sendUserFeedback = async (
  recordId,
  positive,
  conversationId,
  feedback
) => {
  const logUrl = `${getApiURL()}/instance/mark-record`;

  if (logUrl) {
    const postData = {
      positive: positive,
    };
    if (recordId) {
      postData.recordId = recordId;
    }
    if (conversationId) {
      postData.conversationId = conversationId;
    }
    if (feedback) {
      postData.feedback = feedback;
    }
    try {
      await axios.post(logUrl, postData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
    } catch (err) {
      console.error("Failed to log user feedback:", err.message);
    }
  }
};

export const randomId = () => {
  return String(Date.now().toString(32) + Math.random().toString(16)).replace(
    /\./g,
    ""
  );
};

export const renderIcon = (iconName, color, strokeWidth = 2) => {
  switch (iconName) {
    case "PENCIL":
      return <PencilIcon color={color} strokeWidth={strokeWidth} />;
    case "STAR":
      return <StarIcon color={color} strokeWidth={strokeWidth} />;
    case "LIGHTBULB":
      return <LightbulbIcon color={color} strokeWidth={strokeWidth} />;
    case "COMPASS":
      return <CompassIcon color={color} strokeWidth={strokeWidth} />;
    case "QUESTION":
      return <QuestionIcon color={color} strokeWidth={strokeWidth} />;
    default:
      return null; // Or return a default icon or nothing
  }
};

export const translateQuestions = async (
  questionsObj,
  language,
  openQuestionsEnabled
) => {
  const url = `${getApiURL()}/translate`;

  // Build the request body dynamically based on provided question sections
  const requestBody = {
    questions: [],
    language: language,
  };

  // Add 'open' section if it exists
  if (questionsObj.open && openQuestionsEnabled) {
    requestBody.questions.push({
      key: "open",
      questions: questionsObj.open,
    });
  }

  // Handle deviceTypes section (if exists)
  if (questionsObj.deviceTypes && questionsObj.deviceTypes.length > 0) {
    questionsObj.deviceTypes.forEach(
      ({ manufacturerName, deviceName, exampleQuestions }) => {
        if (exampleQuestions.length > 0) {
          requestBody.questions.push({
            key: `${manufacturerName}:${deviceName}`, // Dynamic key for manufacturerName:deviceName
            questions: exampleQuestions,
          });
        }
      }
    );
  }

  // Handle devices section (if deviceTypes is empty but devices are available)
  if (questionsObj.devices && questionsObj.devices.length > 0) {
    questionsObj.devices.forEach(({ deviceName, exampleQuestions }) => {
      if (exampleQuestions.length > 0) {
        requestBody.questions.push({
          key: `${deviceName}`, // Use deviceName as the key
          questions: exampleQuestions, // Questions for each device
        });
      }
    });
  }

  // Handle categoriesContainer section (if both deviceTypes and devices are empty)
  if (questionsObj.categories && questionsObj.categories.length > 0) {
    questionsObj.categories.forEach(
      ({ categoryName, deviceName, exampleQuestions }) => {
        if (exampleQuestions.length > 0) {
          requestBody.questions.push({
            key: `${categoryName}:${deviceName}`, // Use categoryName:deviceName as the key
            questions: exampleQuestions, // Questions for each device in category
          });
        }
      }
    );
  }

  try {
    const response = await axios.post(url, requestBody);
    return response.data;
  } catch (error) {
    console.error("Error translating questions:", error);
    return [];
  }
};

export const replaceQuestions = (questionsArray, newQuestions) => {
  return questionsArray.map((item, index) => ({
    ...item,
    question: newQuestions[index].trim(), // Remove leading spaces
  }));
};

// Function to normalize color code to lowercase 6-character hex
export const normalizeColor = (color) => {
  // Convert 3-character hex to 6-character hex if needed
  if (color.length === 4) {
    color = `#${color[1]}${color[1]}${color[2]}${color[2]}${color[3]}${color[3]}`;
  }
  return color.toLowerCase();
};
