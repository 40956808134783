import React, { useState, useEffect, useMemo, useRef, useContext } from "react";
import axios from "axios";
import { handleSendResult, handleFeedbackSubmit } from "../zaps/zaps";
import {
  getBrightness,
  audioBlobToBase64,
  formatDeviceName,
  randomId,
  checkLimit,
} from "../utils";
import StepsComponent from "./StepsComponent";
import NoResults from "./NoResults";
import ProgressBar from "./ProgressBar";
import VoiceAnimation from "./VoiceAnimation";
import Warning from "./Warning";
import AutoSelect from "./AutoSelect";
import AutoSelectDistributorsCustomer from "./AutoSelectDistributorsCustomers";
import DownloadIcon from "../assets/download";
import ArrowRightIcon from "../assets/arrowRight";
// import { ReactComponent as CloseIcon } from "../assets/close.svg";
import ThumbUpIconCustomer from "../assets/thumbUpCustomer";
import ThumbDownIconCustomer from "../assets/thumbDownCustomer";
import MicrophoneIcon from "../assets/mic";
import Alert from "./Alert";
import { useUIText } from "../hooks";
import Faqs from "./Faqs";
import AutoResizeTextarea from "./TextArea";
import AutoSelectManufacturerAdvCustomer from "./AutoSelectManufacturerAdvCustomer";
import { sendQuestionData, sendUserFeedback, normalizeColor } from "../utils";
import ExampleQuestions from "./ExampleQuestions";
import SAicon from "../assets/sa-icon";
import ConversationFeedback from "./ConversationFeedback";
import { AppContext } from "../context";
import { Tooltip } from "react-tooltip";
import OtherQuestionsChatbot from "./OtherQuestionsChatbot";
import SelectOnWelcomeChatbot from "./SelectOnWelcomeChatbot";
import WebIcon from "../assets/web";
import ChatIcon from "../assets/chat";
import CloseIcon from "../assets/close";
import Skeleton from "./Skeleton";
import { ReactComponent as BubbleIcon } from "../assets/bubble.svg";
import FileIcon from "../assets/file";

const languageCodeMap = {
  English: "en-US",
  German: "de-DE",
  French: "fr-FR",
  Polish: "pl-PL",
  Spanish: "es-ES",
};

const FormContainer = ({
  variables,
  logo,
  typeOfDemo,
  handleViewChange,
  url,
  isTesting = false,
  feedbackIsAdded,
  setShowFullAccess,
  mainColor,
}) => {
  const uiText = useUIText();

  const { instanceInfo } = useContext(AppContext);
  const mainLanguage = instanceInfo.mainLanguage;

  const environment = process.env.REACT_APP_ENV;

  let options = [
    { value: "English", label: "English" },
    // { value: "Arabic", label: "Arabic" },
    { value: "Bulgarian", label: "Bulgarian" },
    { value: "Chinese", label: "Chinese" },
    { value: "Czech", label: "Czech" },
    { value: "Danish", label: "Danish" },
    { value: "Dutch", label: "Dutch" },
    { value: "Estonian", label: "Estonian" },
    { value: "Finnish", label: "Finnish" },
    { value: "French", label: "French" },
    { value: "German", label: "German" },
    { value: "Greek", label: "Greek" },
    { value: "Hungarian", label: "Hungarian" },
    { value: "Indonesian", label: "Indonesian" },
    { value: "Italian", label: "Italian" },
    { value: "Japanese", label: "Japanese" },
    { value: "Korean", label: "Korean" },
    { value: "Latvian", label: "Latvian" },
    { value: "Lithuanian", label: "Lithuanian" },
    { value: "Norwegian", label: "Norwegian" },
    { value: "Polish", label: "Polish" },
    { value: "Portuguese", label: "Portuguese" },
    { value: "portuguese-brazilian", label: "Portuguese (Brazilian)" },
    { value: "Romanian", label: "Romanian" },
    { value: "Russian", label: "Russian" },
    { value: "Slovak", label: "Slovak" },
    { value: "Slovenian", label: "Slovenian" },
    { value: "Spanish", label: "Spanish" },
    { value: "Swedish", label: "Swedish" },
    { value: "Turkish", label: "Turkish" },
    { value: "Ukrainian", label: "Ukrainian" },
  ];

  // Check if the default language is already in the options
  let defaultOptionIndex = options.findIndex(
    (option) => option.value === mainLanguage
  );

  if (defaultOptionIndex !== -1) {
    // If it is, modify its label to include '(default)'
    options[defaultOptionIndex].label = `${options[defaultOptionIndex].label}`;
    // Move the default option to the top of the array
    options.unshift(options.splice(defaultOptionIndex, 1)[0]);
  } else {
    // If it's not, add it to the options at the top
    options.unshift({
      value: mainLanguage,
      label: `${mainLanguage}`,
    });
  }
  const [reset, setReset] = useState(false);
  const [userInput, setUserInput] = useState({
    installer_fallback: true,
    gpt: true,
    company: variables?.slug,
    demo_name: variables?.slug,
    device: "",
    problem: "",
    language: mainLanguage,
    instance_type: "installer",
    config_name: variables.consumerFriendly
      ? "config_customer"
      : "config_default",
    instanceId: process.env.REACT_APP_INSTANCE_ID,
  });
  const [chatOpen, setChatOpen] = useState(false);
  const [conversationFeedbackAdded, setConversationFeedbackAdded] =
    useState(false);

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [fileLoading, setFileLoading] = useState(false);
  const [fileError, setFileError] = useState(null);
  const [error, setError] = useState(null);
  const [titleClicked, setTitleClicked] = useState(false);
  const [progress, setProgress] = useState(0);
  const [status, setStatus] = useState(uiText?.status?.processing);
  const [delay, setDelay] = useState(false);
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [showData, setShowData] = useState(false);
  const [showQuestion, setShowQuestion] = useState(false);
  const [feedback, setFeedback] = useState(null);

  const [showBackButton, setShowBackButton] = useState(false);

  const [recording, setRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [transcription, setTranscription] = useState("");
  const [isSpinnerVisible, setIsSpinnerVisible] = useState(false);
  const [backBtnHovered, setBackBtnHovered] = useState(false);
  const [exampleQuestions, setExampleQuestions] = useState([]);
  const [displayedFaq, setDisplayedFaq] = useState(null);
  const [showFaq, setShowFaq] = useState(true);
  const [relatedDevices, setRelatedDevices] = useState([]);
  const [conversationId, setConversationId] = useState(null); //for admin backend
  const [sessionId, setSessionId] = useState(null); //for numlab api
  const [conversation, setConversation] = useState([]);
  const [firstQuestion, setFirstQuestion] = useState("");
  const [relatedDeviceClicked, setRelatedDeviceClicked] = useState(false);
  const [resetFeedback, setResetFeedback] = useState(false);
  const [feedbackDisabled, setFeedbackDisabled] = useState(false);
  const [welcomeOptionSelected, setWelcomeOptionSelected] = useState(2);
  const [isAnimating, setIsAnimating] = useState(false);

   const useDefaultColor = useMemo(() => {
			return (
				normalizeColor(variables.mainColor) === "#ffffff" ||
				variables.mainColor.toLowerCase() === "#5a55ab"
			);
		}, [variables]);

  const faqAvailable = useMemo(() => {
    if (
      variables.faq?.length > 0 &&
      variables.faq.some((faq) => faq.selected)
    ) {
      return true;
    }
    return false;
  }, [variables.faq]);

  const otherQuestionsEnabled = variables.otherQuestionsEnabled;

  useEffect(() => {
    setUserInput((prevUserInput) => ({
      ...prevUserInput,
      language: mainLanguage,
    }));
  }, [mainLanguage]);

  useEffect(() => {
    if (
      userInput.device !== "" &&
      userInput.device !== "general" &&
      variables.type === "Manufacturer"
    ) {
      setExampleQuestions(
        variables.devices.find(
          (device) => device.deviceName === userInput.device
        ).exampleQuestions
      );
      setRelatedDevices(
        variables.devices.find(
          (device) => device.deviceName === userInput.device
        ).relatedDevices || []
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInput.device, variables.devices]);
  const fetchData = React.useCallback(async () => {
    conversation.length < 1 && setFirstQuestion(userInput.problem);
    setShowQuestion(true);
    setTimeout(() => {
      setLoading(true);
    }, 10);
    // Define a variable to store the updated device value
    let updatedDevice = userInput.device;
    let updatedCompany = userInput.company;
    let updatedDeviceType = userInput.deviceType;
    let updatedManufacturer = userInput.manufacturer;

    if (otherQuestionsEnabled && welcomeOptionSelected === 2) {
      updatedDevice = "general";
      updatedCompany = variables?.slug;
      updatedDeviceType = "";
      updatedManufacturer = "";
      setUserInput((prevInput) => ({
        ...prevInput,
        device: "general",
        company: updatedCompany,
        deviceType: updatedDeviceType,
        manufacturer: updatedManufacturer,
      }));
    }
    try {
      let session_id = sessionId;
      if (!sessionId) {
        session_id = randomId();
        setSessionId(session_id);
      }

      if (url) {
        const reachedLimit = await checkLimit();
        if (reachedLimit) {
          setShowFullAccess(true);
          setLoading(false);
          return;
        }
        const res = await axios.post(
          url,
          {
            ...userInput,
            session_id: session_id,
            device: updatedDevice,
            manufacturer: updatedManufacturer,
            deviceType: updatedDeviceType,
            company: updatedCompany,

            ...(variables?.smaEndpoint
              ? {
                  logfile: "stp50logs.csv",
                }
              : {}),
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (res.data) {
          const newConversationEntry = {
            question: userInput.problem,
            data: res.data,
            recordId: null,
            showThumbs: true,
            device: updatedDevice,
            conversationError: false,
          };
          setData(res.data);
          setConversation((prevConversation) => [
            ...prevConversation,
            newConversationEntry,
          ]);
          // const element = document.getElementById("results");
          // if (element) window.scrollTo(0, element.offsetTop);
          setConversationFeedbackAdded(false);
          if (!isTesting) {
            sendQuestionData(
              userInput,
              res.data?.model_response || "",
              res.data?.exact_file || "",
              res.data?.page || [],
              session_id,
              conversationId,
              false,
              res.data?.empty_answer,
              otherQuestionsEnabled
            ).then((response) => {
              if (response && response.recordId) {
                setConversation((prevConversation) =>
                  prevConversation.map((entry, index) =>
                    index === prevConversation.length - 1
                      ? { ...entry, recordId: response.recordId }
                      : entry
                  )
                );
              }
              if (response && response.conversationId) {
                setConversationId(response.conversationId);
              }
              if (response.limitReached) {
                setShowFullAccess(true);
              }
            });
          }
        } else {
          setData([]);
        }
      }
    } catch (err) {
      const newConversationEntry = {
        question: userInput.problem,
        data: null,
        recordId: null,
        showThumbs: false,
        device: updatedDevice,
        conversationError: true,
      };
      setConversation((prevConversation) => [
        ...prevConversation,
        newConversationEntry,
      ]);
      setError(err.message); // Set only the error message
      if (environment === "production" && !isTesting) {
        handleSendResult(
          false,
          data,
          userInput,
          variables.slug,
          !variables?.oldAi ?? true
        );
      }
      setShowProgressBar(false);
      setShowQuestion(false);
      setProgress(0);
      setStatus("");
      if (!isTesting) {
        sendQuestionData(
          userInput,
          err.message || "error",
          "",
          [],
          sessionId,
          conversationId,
          true,
          true,
          otherQuestionsEnabled
        ).then((response) => {
          if (response && response.recordId) {
            setConversation((prevConversation) =>
              prevConversation.map((entry, index) =>
                index === prevConversation.length - 1
                  ? { ...entry, recordId: response.recordId }
                  : entry
              )
            );
          }
          if (response && response.conversationId) {
            setConversationId(response.conversationId);
          }
        });
      }
    }
    setConversationFeedbackAdded(false);
    setLoading(false);
    setTitleClicked(false);
    setFileError(null);
    setProgress(100); // Set progress to 100
    setStatus(uiText?.status?.complete); // Set status to "Complete"
    setTimeout(() => {
      setProgress(0);
      setStatus("");
      setShowProgressBar(false);
      setShowQuestion(false);
      setShowData(true);
    }, 300);
    setUserInput((prevUserInput) => ({
      ...prevUserInput,
      problem: "",
    }));
    setRelatedDeviceClicked(false);
  }, [
    sessionId,
    otherQuestionsEnabled,
    environment,
    setShowFullAccess,
    isTesting,
    uiText?.status?.complete,
    variables?.smaEndpoint,
    url,
    userInput,
    welcomeOptionSelected,
    variables?.oldAi,
    variables?.slug,
    data,
    conversationId,
    conversation?.length,
    setConversationFeedbackAdded,
    setFileError,
    setTitleClicked,
    setLoading,
    setRelatedDeviceClicked,
    setUserInput,
    setProgress,
    setStatus,
    setShowProgressBar,
    setShowQuestion,
    setShowData,
  ]);

  useEffect(() => {
    let interval = null;
    if (loading && !delay) {
      setShowProgressBar(true);
      if (progress === 0) {
        setStatus(uiText?.status?.processing);
      }
      interval = setInterval(() => {
        setProgress((prevProgress) => {
          let newProgress = prevProgress + 1;
          if (newProgress > 100) {
            newProgress = 100; // Ensure progress does not exceed 100
          }
          if (newProgress === 10 || newProgress === 30 || newProgress === 50) {
            setStatus(
              newProgress === 10
                ? uiText?.status?.processing
                : newProgress === 30
                ? uiText?.status?.searching
                : uiText?.status?.generating
            );
            clearInterval(interval);
            setDelay(true);
            setTimeout(() => setDelay(false), 100); // Add a delay of half a second
          }
          return newProgress;
        });
      }, 100); // Adjust the time here to control the speed of the progress bar
    } else if (!loading && progress > 1) {
      setProgress(100);
      setStatus(uiText?.status?.complete);
      setTimeout(() => {
        setProgress(0);
        setStatus("");
        setShowProgressBar(false);
        setTimeout(() => setShowData(true), 300); // Delay showing data until after ProgressBar has disappeared
      }, 500);
    }
    return () => clearInterval(interval);
  }, [loading, delay, progress, uiText]);

  const openFile = async (fileName, page, range) => {
    setFileLoading(true);
    try {
      let url = null;
      if (variables.oldAi) {
        url =
          process.env.REACT_APP_ENV === "production"
            ? "https://ai.service-assistant.ai/file/download"
            : "https://ai.service-assistant-staging.com/file/download";
      } else {
        url =
          process.env.REACT_APP_ENV === "production"
            ? "https://new-ai.service-assistant.ai/file/download"
            : "https://new-ai.service-assistant-staging.com/file/download";
      }
      const response = await axios({
        method: "POST",
        url,
        data: {
          filename: `${fileName}`,
          company:
            variables.type === "Manufacturer"
              ? variables.slug
              : String(userInput.company).toLowerCase(),
          instanceId: process.env.REACT_APP_INSTANCE_ID,
        },
        responseType: "arraybuffer",
      });
      if (response.data) {
        const blob = new Blob([response.data], { type: "application/pdf" });
        let url = window.URL.createObjectURL(blob);

        // Convert page and range to an array if they are not already
        // and convert elements to string if they are numbers
        const pageArray = Array.isArray(page)
          ? page.map(String)
          : [String(page)];
        const rangeArray = Array.isArray(range)
          ? range.map(String)
          : [String(range)];

        // Check if the range array is not empty
        if (rangeArray.length > 0 && rangeArray[0]) {
          url += "#page=" + rangeArray[0];
        }
        // If the range array is empty, check if the page array is not empty
        else if (pageArray.length > 0 && pageArray[0]) {
          url += "#page=" + pageArray[0];
        }

        if (window.confirm(uiText?.alerts?.openInNewTab)) {
          window.open(url, "_blank");
        }
      }
    } catch (err) {
      setFileError(err);
      console.log(err);
    }
    setFileLoading(false);
  };

  const handleSubmit = React.useCallback(
    (e) => {
      e && e.preventDefault();
      if (!userInput) return;
      setData({});
      fetchData();
      // setUserInput({ device: "", problem: "", language: "English" });
      setError(null);
      setFileError(null);
      setShowBackButton(false);
      setTimeout(() => {
        const questions = document.querySelectorAll(".customer-question");
        const lastQuestion = questions[questions.length - 1];
        if (lastQuestion) {
          lastQuestion.scrollIntoView({ behavior: "smooth" });
        }
      }, 500);
    },
    [setData, fetchData, setError, setFileError, setShowBackButton, userInput]
  );

  useEffect(() => {
    if (
      Object.keys(data).length > 0 &&
      environment === "production" &&
      !isTesting
    ) {
      handleSendResult(
        true,
        data,
        userInput,
        variables.slug,
        !variables?.oldAi ?? true
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]); // This effect runs whenever `data` changes

  useEffect(() => {
    if (conversationFeedbackAdded) {
      setConversationFeedbackAdded(true);
      setTimeout(() => {
        setConversationFeedbackAdded(false);
      }, 3500);
    } else {
      setConversationFeedbackAdded(false);
    }
  }, [conversationFeedbackAdded, setConversationFeedbackAdded]);

  useEffect(() => {
    if (titleClicked) {
      handleSubmit();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [titleClicked, handleSubmit]);

  // Cleanup function to stop recording and release media resources
  useEffect(() => {
    return () => {
      if (mediaRecorder) {
        mediaRecorder.stream.getTracks().forEach((track) => track.stop());
      }
    };
  }, [mediaRecorder]);

  if (!process.env.REACT_APP_GOOGLE_API_KEY) {
    throw new Error("REACT_APP_GOOGLE_API_KEY not found in the environment");
  }

  const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });
      const recorder = new MediaRecorder(stream);
      recorder.start();
      console.log("Recording started");
      setUserInput({
        ...userInput,
        problem: "",
      });

      // Event listener to handle data availability
      recorder.addEventListener("dataavailable", async (event) => {
        console.log("Data available event triggered");
        const audioBlob = event.data;

        const base64Audio = await audioBlobToBase64(audioBlob);
        //console.log('Base64 audio:', base64Audio);
        setIsSpinnerVisible(true);
        try {
          const startTime = performance.now();

          const response = await axios.post(
            `https://speech.googleapis.com/v1/speech:recognize?key=${apiKey}`,
            {
              config: {
                encoding: "WEBM_OPUS",
                sampleRateHertz: 48000,
                languageCode: languageCodeMap[userInput.language] || "en-US",
                enableAutomaticPunctuation: true,
                useEnhanced: true,
                model: "latest_long",
              },
              audio: {
                content: base64Audio,
              },
            }
          );

          const endTime = performance.now();
          const elapsedTime = endTime - startTime;

          //console.log('API response:', response);
          console.log("Time taken (ms):", elapsedTime);

          if (response.data.results && response.data.results.length > 0) {
            setIsSpinnerVisible(false);
            setTranscription(
              response.data.results[0].alternatives[0].transcript
            );
            setUserInput({
              ...userInput,
              problem: response.data.results[0].alternatives[0].transcript,
            });
          } else {
            console.log(
              "No transcription results in the API response:",
              response.data
            );
            setTranscription(uiText?.form?.noTranscription);
          }
          setIsSpinnerVisible(false);
        } catch (error) {
          console.error(
            "Error with Google Speech-to-Text API:",
            error.response.data
          );
        }
      });

      setRecording(true);
      setMediaRecorder(recorder);
    } catch (error) {
      console.error("Error getting user media:", error);
    }
  };

  const stopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
      console.log("Recording stopped");
      setRecording(false);
      // Stop the MediaStream
      mediaRecorder.stream.getTracks().forEach((track) => track.stop());
    }
  };

  const hideForm =
    showProgressBar ||
    error ||
    fileError ||
    (showData && Object.keys(data).length > 0) ||
    loading ||
    showQuestion ||
    displayedFaq !== null ||
    conversation.length > 0;

  useEffect(() => {
    if (
      !loading &&
      ((showData && Object.keys(data).length > 0) || error || fileError)
    ) {
      setTimeout(() => {
        setShowBackButton(true);
      }, 1000);
    }
  }, [showData, data, error, fileError, loading]);

  const handleFaqClick = (faq) => {
    setDisplayedFaq(faq);
    setData({});
    setShowBackButton(true);
    setShowFaq(false);
  };
  useEffect(() => {
    if (faqAvailable && exampleQuestions.length === 0) {
      setShowFaq(true);
    } else {
      setShowFaq(false);
    }
    // if (exampleQuestions.length > 0) {
    // 	setShowFaq(false);
    // }
  }, [faqAvailable, exampleQuestions]);

  const faqContainerClass =
    showFaq && faqAvailable && !hideForm
      ? "faq-wrapper visible child-element"
      : "faq-wrapper hidden child-element";
  const exampleQuestionsClass =
    !showFaq && exampleQuestions.length > 0 && !hideForm
      ? "example-questions-container visible"
      : "example-questions-container isHidden child-element";
  const welcomeOptionsClass =
    otherQuestionsEnabled && !hideForm
      ? "faq-wrapper visible child-element"
      : "faq-wrapper hidden child-element";
  const selectWithOtherQuestionsClass =
    otherQuestionsEnabled && !hideForm && welcomeOptionSelected === 1
      ? "select-with-other-questions-up"
      : otherQuestionsEnabled && !hideForm && welcomeOptionSelected === 2
      ? "select-with-other-questions-down isSelectHidden"
      : "";
  const inputWithOtherQuestionsClass =
    otherQuestionsEnabled && !hideForm && welcomeOptionSelected === 1
      ? "input-with-other-questions-down"
      : otherQuestionsEnabled && !hideForm && welcomeOptionSelected === 2
      ? "input-with-other-questions-up"
      : "";

  const handleClickQuestion = (question) => {
    setUserInput({
      ...userInput,
      problem: question,
    });
  };

  const latestConversationRef = useRef(null);

  useEffect(() => {
    if (conversation.length > 0) {
      setResetFeedback(true);
    }
    // Scroll to the latest conversation entry
    if (latestConversationRef.current) {
      latestConversationRef.current.scrollIntoView({ behavior: "smooth" });
    }
    setTimeout(() => {
      setResetFeedback(false);
    }, 0);
  }, [conversation.length]);

  const UserFeedback = ({ recordId, data, question, index, showingThumbs }) => {
    const [feedbackAdded, setFeedbackAdded] = useState(false);
    const [showThumbs, setShowThumbs] = useState(true);
    const [showFeedbackInput, setShowFeedbackInput] = useState(false);
    const [feedbackInput, setFeedbackInput] = useState("");

    useEffect(() => {
      if (feedbackAdded) {
        setTimeout(() => {
          setShowThumbs(false);
          setConversationThumb(index, false);
        }, 3500);
      } else {
        setShowThumbs(true);
      }
    }, [feedbackAdded, index]);

    if (!showThumbs || !showingThumbs) {
      return null;
    }

    return (
      <div
        className={
          showFeedbackInput ? "user-feedback show-input" : "user-feedback"
        }
      >
        {feedbackAdded ? (
          <div className="user-feedback__text success">
            {uiText?.result?.feedback.thankYou}
          </div>
        ) : showFeedbackInput ? (
          <div className="user-feedback__input">
            {/* <div
								className="close-button__feedback "
								onClick={() => setShowFeedbackInput(false)}
							>
								<CloseIcon />
							</div> */}
            {/* <div className="feedback-text">
								{uiText?.result?.feedback.description}
							</div> */}
            <textarea
              value={feedbackInput}
              onChange={(e) => setFeedbackInput(e.target.value)}
              placeholder={uiText?.result?.feedback.inputPlaceholder}
              type="text"
              id="feedback-input"
              name="feedback-input"
              className="card"
              style={{
                maxHeight: "250px",
                minHeight: "42px",
                height: "42px",
              }}
            />
            <button
              type="submit"
              className="submit-btn"
              style={{
                backgroundColor: mainColor,
                color:
                  getBrightness(mainColor) > 155 ? "black" : "white",
                border: `1px solid ${mainColor}`,
                marginTop: "0px",
              }}
              onClick={() => {
                if (environment === "production" && !isTesting) {
                  handleFeedbackSubmit(
                    "Thumb Down",
                    data,
                    question,
                    setFeedbackAdded,
                    variables.slug,
                    feedbackInput,
                    setShowFeedbackInput,
                    !variables?.oldAi ?? true
                  );
                } else {
                  setFeedbackAdded(true);
                  setShowFeedbackInput(false);
                }
                if (!isTesting) {
                  sendUserFeedback(
                    recordId,
                    false,
                    conversationId,
                    feedbackInput
                  );
                }
                setConversationThumb(index, false);
              }}
            >
              <div className="thumb-button">
                {/* <ThumbDownIconCustomer
										color={
											getBrightness(mainColor) > 155
												? "black"
												: "white"
										}
									/> */}
                {uiText?.result?.feedback.submit}
              </div>
            </button>
          </div>
        ) : (
          <>
            <div className="user-feedback__text">
              {uiText?.result?.feedback.title}
            </div>
            <div className="user-feedback__buttons">
              <div
                className="user-feedback__button"
                onClick={() => setShowFeedbackInput(true)}
                style={{ borderColor: "#495B84" }}
              >
                <ThumbDownIconCustomer color={"#495B84"} />
              </div>
              <div
                className="user-feedback__button"
                onClick={() => {
                  if (environment === "production" && !isTesting) {
                    handleFeedbackSubmit(
                      "Thumb Up",
                      data,
                      question,
                      setFeedbackAdded,
                      variables.slug,
                      "",
                      null,
                      !variables?.oldAi ?? true
                    );
                  } else {
                    setFeedbackAdded(true);
                  }
                  if (!isTesting) {
                    sendUserFeedback(recordId, true, conversationId);
                  }
                  setConversationThumb(index, false);
                }}
                style={{ borderColor: "#495B84" }}
              >
                <ThumbUpIconCustomer color={"#495B84"} />
              </div>
            </div>
          </>
        )}
      </div>
    );
  };

  const onConversationFeedbackSubmit = (value) => {
    if (!isTesting) {
      sendUserFeedback(null, value, conversationId);
    }
    setFeedback(value);
    setFeedbackDisabled(true);
  };

  const checkIfFeedbackIsNeeded = (item) => {
    return (
      item.data?.model_response.length > 0 && item.data?.empty_answer === false
    );
  };

  let firstValidItemFound = false;

  const setConversationThumb = (index) => {
    setTimeout(() => {
      setConversation((prevConversation) =>
        prevConversation.map((item, i) =>
          i === index ? { ...item, showThumbs: false } : item
        )
      );
    }, 3500);
  };

  const onBackButtonClick = () => {
    setShowData(false);
    setShowQuestion(false);
    setShowProgressBar(false);
    setError(null);
    setFileError(null);
    setData({});
    setConversationFeedbackAdded(false);
    setShowBackButton(false);
    setBackBtnHovered(false);
    setDisplayedFaq(null);
    setSessionId(null);
    setConversation([]);
    setConversationId(null);
    setResetFeedback(false);
    setFeedbackDisabled(false);
    if (faqAvailable && exampleQuestions.length === 0) {
      setShowFaq(true);
    }
    setUserInput({
      ...userInput,
      problem: "",
    });
    setRelatedDeviceClicked(false);
    setFeedback(null);
  };

  const onWelcomeOptionClick = (value) => {
    setWelcomeOptionSelected(value);
    if (value !== welcomeOptionSelected) {
      onBackButtonClick();
      setReset(true);
      setExampleQuestions([]);
    }
  };

  const isValidFileName = (str) => {
    return str !== "None" && (isFile(str) || isValidUrl(str));
  };

  const isFile = (str) => {
    const commonExtensions = [".pdf", ".docx", ".xlsx", ".pptx", ".txt"];
    return commonExtensions.some((ext) => str.toLowerCase().endsWith(ext));
  };

  const isValidUrl = (str) => {
    return (
      str.includes("/") || str.startsWith("http") || str.startsWith("https")
    );
  };

  const getFullUrl = (str) => {
    if (str.startsWith("http")) {
      return str;
    }
    return `https://${str}`;
  };

  // Toggle the chatbot open/close
  const toggleChat = () => {
    setChatOpen(!chatOpen);
  };

  const handleChatBtnClick = () => {
    if (!isAnimating) {
      setIsAnimating(true);
      setTimeout(() => {
        toggleChat(); // Call the parent's toggleChat function
        setIsAnimating(false);
      }, 200); // Match the animation duration
    }
  };
  const devicesExist = React.useMemo(() => {
    switch (variables.type) {
      case "Manufacturer": {
        return variables?.devices.length > 0;
      }
      case "Distributor": {
        return variables?.deviceTypes?.some((deviceType) => {
          return deviceType.manufacturers.some((manufacturer) => {
            return manufacturer.devices.length > 0;
          });
        });
      }
      case "Advanced Manufacturer": {
        return variables?.categoriesContainer?.categories?.some((category) => {
          return category.devices.length > 0;
        });
      }
      default: {
        return false;
      }
    }
  }, [variables]);

  return (
    <>
      <div className="chatbot-app">
        <div
          className={
            chatOpen ? "chatbot-container opened" : "chatbot-container closed"
          }
        >
          {chatOpen ? (
            <div className="chatbot-content">
              <div className={fileLoading ? "modal visible" : "modal"}>
                <div className="modal-content">
                  <span>{uiText?.form?.loadingFile}</span>
                </div>
              </div>
              <div className="chatbot-content__top">
                <div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <BubbleIcon
                      style={{
                        width: "16px",
                        height: "16px",
                        marginRight: "6px",
                      }}
                    />
                    Service Assistant AI
                  </div>
                  {showBackButton && (
                    <div className="back-btn back-btn-mobile">
                      <button
                        onClick={() => {
                          onBackButtonClick();
                        }}
                        onMouseEnter={() => setBackBtnHovered(true)}
                        onMouseLeave={() => setBackBtnHovered(false)}
                        style={{
                          borderColor: "transparent",
                          backgroundColor: "transparent",
                          color: backBtnHovered
                            ? mainColor
                            : "#6982A8",
                          whiteSpace: "nowrap",
                        }}
                      >
                        <span>+ {uiText?.askNewQuestion}</span>
                      </button>
                    </div>
                  )}
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {showBackButton && (
                    <div className="back-btn back-btn-desktop">
                      <button
                        onClick={() => {
                          onBackButtonClick();
                        }}
                        onMouseEnter={() => setBackBtnHovered(true)}
                        onMouseLeave={() => setBackBtnHovered(false)}
                        style={{
                          borderColor: "transparent",
                          backgroundColor: "transparent",
                          color: backBtnHovered
                            ? mainColor
                            : "#6982A8",
                          whiteSpace: "nowrap",
                        }}
                      >
                        <span>+ {uiText?.askNewQuestion}</span>
                      </button>
                    </div>
                  )}
                  <div
                    className="top-close-icon"
                    onClick={handleChatBtnClick}
                    style={{ bottom: showBackButton ? "15px" : "4px" }}
                  >
                    <CloseIcon color="black" />
                  </div>
                </div>
                {fileError && (
                  <Alert
                    message={uiText?.alerts?.fileOpenError}
                    duration={5000} // Duration in milliseconds
                    close={() => setFileError(null)}
                  />
                )}
              </div>
              <div
                className={
                  showBackButton
                    ? "chatbot-content__middle with-padding"
                    : "chatbot-content__middle"
                }
                style={{ overflow: fileLoading ? "hidden" : "auto" }}
              >
                <div
                  className={
                    otherQuestionsEnabled
                      ? "customer-container customer-container--faq customer-container--other"
                      : faqAvailable && showFaq
                      ? "customer-container customer-container--faq"
                      : "customer-container"
                  }
                >
                  <div
                    className="customer conversational"
                    style={{ marginTop: variables.hideHeader ? "32px" : "0px" }}
                  >
                    <div
                      className={`customer-wrapper ${
                        hideForm ? "with-answer" : ""
                      }`}
                      style={{ marginTop: 0 }}
                    >
                      <div
                        className={`form-page ${
                          otherQuestionsEnabled
                            ? "top"
                            : showFaq
                            ? "space-between"
                            : "top"
                        }`}
                      >
                        {!hideForm && (
                          <div
                            className={`avatar-container ${
                              hideForm
                                ? "hide"
                                : variables.hideHeader
                                ? "hidden-header"
                                : variables.active
                                ? "hidden-banner"
                                : ""
                            }`}
                          >
                            <div className="avatar-text">
                              {error ||
                              showProgressBar ||
                              (showData && Object.keys(data).length > 0) ||
                              displayedFaq !== null ? null : (
                                <>
                                  <div className="assistant-avatar">
                                    {variables?.avatarImg ? (
                                      <img
                                        src={variables.avatarImg}
                                        alt="avatar-img"
                                        style={{
                                          maxWidth: "40px",
                                          maxHeight: "40px",
                                        }}
                                      />
                                    ) : (
                                      <SAicon
                                        circleColor={mainColor}
                                        color={
                                          getBrightness(mainColor) >
                                          155
                                            ? "black"
                                            : "white"
                                        }
                                      />
                                    )}

                                    <span>
                                      {variables.avatarName ??
                                        "Service Assistant AI"}
                                    </span>
                                  </div>
                                  <div className="avatar-info chatbot-card">
                                    {uiText.form.greetings}
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        )}
                        {faqAvailable && !otherQuestionsEnabled && (
                          <div className={faqContainerClass}>
                            <Faqs
                              faqs={variables.faq}
                              borderColor={mainColor}
                              displayedFaq={displayedFaq}
                              handleFaqClick={handleFaqClick}
                            />
                          </div>
                        )}
                        {otherQuestionsEnabled && devicesExist ? (
                          <div className={welcomeOptionsClass}>
                            <SelectOnWelcomeChatbot
                              cursor={
                                isSpinnerVisible || recording
                                  ? "not-allowed"
                                  : "pointer"
                              }
                              onClick={
                                isSpinnerVisible || recording || loading
                                  ? null
                                  : onWelcomeOptionClick
                              }
                              isSelected={false}
                              welcomeOptionSelected={welcomeOptionSelected}
                            />
                          </div>
                        ) : null}
                        <div
                          className={`form-container ${hideForm ? "hide" : ""}`}
                        >
                          <form
                            onSubmit={handleSubmit}
                            className={`form ${hideForm ? "hide" : ""}`}
                            style={{
                              height:
                                faqAvailable && showFaq ? "100%" : "inherit",
                            }}
                          >
                            <div
                              className={
                                otherQuestionsEnabled || showFaq
                                  ? "form-group"
                                  : "form-group form-group--full"
                              }
                              style={{
                                marginTop: faqAvailable ? "0px" : "0",
                                justifyContent: "flex-end",
                                flexGrow: faqAvailable ? 1 : 0,
                                height:
                                  faqAvailable && showFaq ? "100%" : "auto",
                              }}
                            >
                              <div className={hideForm ? "chatbot-card" : ""}>
                                {variables.type === "Manufacturer" ? (
                                  <div
                                    className={`manufacturer-selection ${
                                      otherQuestionsEnabled &&
                                      selectWithOtherQuestionsClass
                                    }`}
                                  >
                                    {" "}
                                    {/* <label htmlFor="device">{`${uiText?.form?.device} *`}</label> */}
                                    <div className="selection-title">
                                      {!userInput.device
                                        ? uiText.form.selectDevice
                                        : uiText.form.yourDevice}
                                    </div>
                                    <div className="selection-container">
                                      <div className="customer-selection">
                                        <AutoSelect
                                          items={variables.devices}
                                          setSelectedDevice={(device) =>
                                            setUserInput({
                                              ...userInput,
                                              device,
                                              problem: "",
                                            })
                                          }
                                          device={userInput.device}
                                          menuPlacement={"top"}
                                          reset={reset}
                                          setReset={setReset}
                                          setExampleQuestions={setExampleQuestions}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                ) : variables.type === "Distributor" ? (
                                  <AutoSelectDistributorsCustomer
                                    items={variables.deviceTypes}
                                    userInput={userInput}
                                    setUserInput={setUserInput}
                                    setExampleQuestions={setExampleQuestions}
                                    reset={reset}
                                    setReset={setReset}
                                    handleSetShowFaqs={setShowFaq}
                                    setRelatedDevices={setRelatedDevices}
                                    relatedDeviceClicked={relatedDeviceClicked}
                                    setRelatedDeviceClicked={
                                      setRelatedDeviceClicked
                                    }
                                    extraClassName={
                                      otherQuestionsEnabled &&
                                      selectWithOtherQuestionsClass
                                    }
                                  />
                                ) : (
                                  <AutoSelectManufacturerAdvCustomer
                                    items={variables.categoriesContainer}
                                    userInput={userInput}
                                    setUserInput={setUserInput}
                                    setExampleQuestions={setExampleQuestions}
                                    reset={reset}
                                    setReset={setReset}
                                    handleSetShowFaqs={setShowFaq}
                                    setRelatedDevices={setRelatedDevices}
                                    extraClassName={
                                      otherQuestionsEnabled &&
                                      selectWithOtherQuestionsClass
                                    }
                                  />
                                )}
                              </div>

                              {!showFaq ? (
                                <div
                                  className={
                                    otherQuestionsEnabled
                                      ? "example-questions-container other-questions-container visible"
                                      : exampleQuestionsClass
                                  }
                                >
                                  {exampleQuestions.length > 0 ? (
                                    <div
                                      className="selection-title"
                                      style={{
                                        textAlign: "left",
                                        paddingTop: "20px",
                                      }}
                                    >
                                      {uiText?.form?.exampleQuestions}:
                                    </div>
                                  ) : null}
                                  <ExampleQuestions
                                    exampleQuestions={exampleQuestions}
                                    borderColor={mainColor}
                                    cursor={
                                      isSpinnerVisible || recording
                                        ? "not-allowed"
                                        : "pointer"
                                    }
                                    onClick={
                                      isSpinnerVisible || recording || loading
                                        ? null
                                        : handleClickQuestion
                                    }
                                  />
                                </div>
                              ) : null}

                              {otherQuestionsEnabled &&
                                welcomeOptionSelected === 2 && (
                                  <OtherQuestionsChatbot
                                    exampleQuestions={variables.otherQuestions}
                                    borderColor={mainColor}
                                    cursor={
                                      isSpinnerVisible || recording
                                        ? "not-allowed"
                                        : "pointer"
                                    }
                                    onClick={
                                      isSpinnerVisible || recording || loading
                                        ? null
                                        : handleClickQuestion
                                    }
                                  />
                                )}
                            </div>
                          </form>
                        </div>
                        <div
                          className={
                            showProgressBar ||
                            error ||
                            fileError ||
                            (showData && Object.keys(data).length > 0) ||
                            displayedFaq !== null ||
                            conversation.length > 0
                              ? "feedback-container show"
                              : "feedback-container"
                          }
                          id="results"
                        >
                          {(showProgressBar || conversation.length > 0) && (
                            <div
                              className="customer-question skeleton-question"
                              style={{
                                marginTop:
                                  showProgressBar && conversation.length === 0
                                    ? "24px"
                                    : "0",
                                paddingTop:
                                  showProgressBar && conversation.length === 0
                                    ? "24px"
                                    : "0",
                              }}
                            >
                              <div className="customer-question__top">
                                {uiText?.you}:
                              </div>
                              <div className="customer-question__middle">
                                {firstQuestion}
                              </div>
                              {otherQuestionsEnabled &&
                              userInput.device === "general" ? null : (
                                <div className="customer-question__bottom">
                                  {formatDeviceName(userInput.device)}
                                </div>
                              )}
                            </div>
                          )}
                          <div
                            className={"feedback card hide"}
                            style={{ position: "relative" }}
                          >
                            {faqAvailable && displayedFaq !== null && (
                              <div className="faq-answer__big">
                                <Faqs
                                  faqs={variables.faq}
                                  borderColor={mainColor}
                                  displayedFaq={displayedFaq}
                                  handleFaqClick={handleFaqClick}
                                />
                              </div>
                            )}
                            {error && !conversation?.length && (
                              <NoResults
                                txt={variables.customFallbackMessage ?? ""}
                                protectionSystem={false}
                              />
                            )}
                            {/* {fileError && (
															<Alert
																message={uiText?.alerts?.fileOpenError}
																duration={5000} // Duration in milliseconds
																close={() => setFileError(null)}
															/>
														)} */}
                            {showProgressBar && !conversation.length && (
                              <div className="feedback-instructions">
                                <Skeleton
                                  mainColor={mainColor}
                                  avatarImg={variables.avatarImg}
                                  avatarName={variables.avatarName}
                                  children={
                                    <ProgressBar
                                      progress={progress}
                                      status={status}
                                      color={mainColor}
                                      showBar={false}
                                      showAnimation={false}
                                    />
                                  }
                                />
                              </div>
                            )}

                            {showData && conversation.length > 0 ? (
                              <>
                                {conversation.map((item, index) => {
																	const {
																		data,
																		question,
																		recordId,
																		showThumbs,
																		device,
																		conversationError,
																	} = item;
																	const shouldDisplayRelatedDevices =
																		!firstValidItemFound &&
																		data?.model_response.length > 0 &&
																		!data.empty_answer &&
																		relatedDevices.length > 0;

																	if (shouldDisplayRelatedDevices) {
																		firstValidItemFound = true;
																	}
																	// Flag to check if NoResults has been shown for this conversation
																	let noResultsShown = false;
																	// Unique tooltip ID based on recordId or index
																	const tooltipId = `articleTooltip-${index}`;
																	return (
																		<div
																			key={index}
																			ref={
																				index === conversation.length - 1
																					? latestConversationRef
																					: null
																			}
																		>
																			{index !== 0 ? (
																				<div
																					className="customer-question"
																					style={{ marginTop: "24px" }}
																				>
																					<div className="customer-question__top">
																						{uiText?.you}:
																					</div>
																					<div className="customer-question__middle">
																						{question}
																					</div>
																					{otherQuestionsEnabled &&
																					userInput.device ===
																						"general" ? null : (
																						<div className="customer-question__bottom">
																							{formatDeviceName(device)}
																						</div>
																					)}
																				</div>
																			) : null}
																			<div className="feedback-instructions">
																				{data?.model_response.length > 0 &&
																				!data?.empty_answer ? (
																					<>
																						<div
																							className="assistant-avatar"
																							style={{ paddingBottom: "1rem", paddingTop: "1rem" }}
																						>
																							{variables?.avatarImg ? (
																								<img
																									src={variables.avatarImg}
																									alt="avatar-img"
																									style={{
																										maxWidth: "40px",
																										maxHeight: "40px",
																									}}
																								/>
																							) : (
																								<SAicon
																									circleColor={
																										mainColor
																									}
																									color={
																										getBrightness(
																											mainColor
																										) > 155
																											? "black"
																											: "white"
																									}
																								/>
																							)}

																							<span>
																								{variables.avatarName ??
																									"Service Assistant AI"}
																							</span>
																						</div>
																						<div className="feedback-documents__col chatbot-card">
																							{typeof data.warning ===
																								"string" &&
																							data.warning.length > 0 ? (
																								<Warning
																									warningText={data.warning}
																								/>
																							) : null}
																							<div className="feedback-instruction">
																								<StepsComponent
																									inputText={
																										data?.model_response
																									}
																									language={userInput.language}
																									markerColor={
																										mainColor
																									}
																									markerTextColor={
																										getBrightness(
																											mainColor
																										) > 155
																											? "black"
																											: "white"
																									}
																								/>
																							</div>
																							<div
																								className={
																									data?.exact_file
																										? "feedback-documents"
																										: "feedback-documents none"
																								}
																								style={{ padding: "0px" }}
																							>
																								{(data?.exact_file ||
																									(data?.article_exists &&
																										data?.main_article &&
																										Object.keys(
																											data?.main_article
																										).length > 0)) &&
																								!data?.empty_answer
																									? (() => {
																											let fileName;
																											if (
																												Array.isArray(
																													data.exact_file
																												)
																											) {
																												const fileObj =
																													data.exact_file[0]; // Get the first object from the array
																												fileName =
																													Object.keys(
																														fileObj
																													)[0]; // Get the key of the object which is the file name
																											} else {
																												fileName =
																													data.exact_file;
																											}

																											// Convert page to an array if it's not already
																											const pageArray =
																												Array.isArray(
																													data.used_pages
																												)
																													? data.used_pages
																													: [data.used_pages];
																											const pageText =
																												pageArray.join(", ");
																											const isMultiplePages =
																												pageArray.length > 1;

																											return (
																												<div
																													className="feedback-documents__col"
																													style={{
																														padding: "1rem 0 0",
																														marginTop: "1rem",
																													}}
																												>
																													<UserFeedback
																														recordId={recordId}
																														data={data}
																														question={question}
																														index={index}
																														showingThumbs={
																															showThumbs
																														}
																													/>
																													<div className="feedback-file conversational__feedback-file">
																														{isValidFileName(
																															fileName
																														) ||
																														(data?.main_article &&
																															Object.keys(
																																data?.main_article
																															).length > 0) ? (
																															<>
																																<div className="file-container__header">
																																	{
																																		uiText
																																			?.result
																																			?.sources
																																	}
																																	:
																																</div>
																																<div className="tiles-container">
                                                                {isValidFileName(
																															fileName
																														) ? (
																																	<div
																																		className="file-container__content tile"
																																		onClick={() => {
																																			if (
																																				isFile(
																																					fileName
																																				)
																																			) {
																																				openFile(
																																					fileName,
																																					data.used_pages ||
																																						[],
																																					data.page_range ||
																																						[]
																																				);
																																			} else {
																																				const fullUrl =
																																					getFullUrl(
																																						fileName
																																					);
																																				if (
																																					window.confirm(
																																						`Do you want to open ${fullUrl} in a new tab?`
																																					)
																																				) {
																																					window.open(
																																						fullUrl,
																																						"_blank",
																																						"noopener,noreferrer"
																																					);
																																				}
																																			}
																																		}}
																																	>
																																		<div
																																			className="file-container__file"
																																			style={{
																																				alignItems:
																																					"start",
																																			}}
																																		>
																																			<div
																																				className="file-title"
																																				style={{
																																					color:
																																						mainColor,
																																					wordBreak:
																																						"break-word",
																																				}}
																																			>
																																				<div
																																					style={{
																																						width:
																																							"16px",
																																						height:
																																							"16px",
																																					}}
																																				>
																																					{isFile(
																																						fileName
																																					) ? (
																																						<DownloadIcon
																																							color={
																																								mainColor
																																							}
																																						/>
																																					) : (
																																						<WebIcon
																																							color={
																																								mainColor
																																							}
																																						/>
																																					)}
																																				</div>
																																				<span
																																					style={{
																																						color:
																																							mainColor,
																																					}}
																																				>
																																					{
																																						fileName
																																					}{" "}
																																				</span>
																																				{isFile(
																																					fileName
																																				) ? (
																																					<div
																																						className="feedback-type"
																																						style={{
																																							background:
																																								mainColor,
																																							color:
																																								getBrightness(
																																									mainColor
																																								) >
																																								155
																																									? "#000000"
																																									: "#ffffff",
																																						}}
																																					>
																																						PDF
																																					</div>
																																				) : null}
																																			</div>
																																			{isFile(
																																				fileName
																																			) ? (
																																				<div className="file-title__small-text">
																																					{pageText?.length ? (
																																						<span>
																																							{isMultiplePages
																																								? uiText?.result?.pages.toLowerCase()
																																								: uiText?.result?.page.toLowerCase()}{" "}
																																							{
																																								pageText
																																							}
																																						</span>
																																					) : null}
																																				</div>
																																			) : null}
																																		</div>
																																	</div>) : null}
																																	{data?.main_article &&
																																	Object.keys(
																																		data?.main_article
																																	).length >
																																		0 ? (
																																		<div
																																			key={
																																				tooltipId
																																			}
																																			id={
																																				tooltipId
																																			}
																																			data-tooltip-id={
																																				tooltipId
																																			}
																																			style={{
																																				display:
																																					"flex",
																																			}}
																																		>
																																			<div
																																				className="file-container__content tile"
																																				onClick={() => {
																																					const fullUrl =
																																						getFullUrl(
																																							data
																																								?.main_article
																																								.url
																																						);
																																					if (
																																						window.confirm(
																																							`Do you want to open ${fullUrl} in a new tab?`
																																						)
																																					) {
																																						window.open(
																																							fullUrl,
																																							"_blank",
																																							"noopener,noreferrer"
																																						);
																																					}
																																				}}
																																			>
																																				<div
																																					className="file-container__file"
																																					style={{
																																						alignItems:
																																							"start",
																																					}}
																																				>
																																					<div
																																						className="file-title"
																																						style={{
																																							color:
																																								mainColor,
																																							wordBreak:
																																								"break-word",
																																						}}
																																					>
																																						<div
																																							style={{
																																								width:
																																									"16px",
																																								height:
																																									"16px",
																																							}}
																																						>
																																							<FileIcon
																																								color={
																																									mainColor
																																								}
																																							/>
																																						</div>
																																						<span
																																							style={{
																																								color:
																																									mainColor,
																																							}}
																																						>
																																							{
																																								data
																																									?.main_article
																																									.title
																																							}{" "}
																																						</span>
																																						<div
																																							className="feedback-type"
																																							style={{
																																								background:
																																									mainColor,
																																								color:
																																									getBrightness(
																																										mainColor
																																									) >
																																									155
																																										? "#000000"
																																										: "#ffffff",
																																							}}
																																						>
																																							Knowledge
																																							Article
																																						</div>
																																					</div>
																																					<div className="file-title__small-text tile-text__small">
																																						{data
																																							?.main_article
																																							.url ? (
																																							<span>
																																								{
																																									data
																																										?.main_article
																																										.url
																																								}
																																							</span>
																																						) : null}
																																					</div>
																																				</div>
																																			</div>
																																			<Tooltip
																																				id={
																																					tooltipId
																																				}
																																				className="article-tooltip"
																																				place="top"
																																				effect="solid"
																																				positionStrategy="fixed"
																																				noArrow={
																																					true
																																				}
																																				type="light"
																																				clickable={
																																					true
																																				}
																																				delayShow={
																																					300
																																				}
																																			>
																																				<div className="article-preview">
																																					<div
																																						className="article-preview__title"
																																						onClick={() => {
																																							const fullUrl =
																																								getFullUrl(
																																									data
																																										?.main_article
																																										.url
																																								);
																																							if (
																																								window.confirm(
																																									`Do you want to open ${fullUrl} in a new tab?`
																																								)
																																							) {
																																								window.open(
																																									fullUrl,
																																									"_blank",
																																									"noopener,noreferrer"
																																								);
																																							}
																																						}}
																																					>
																																						{
																																							data
																																								?.main_article
																																								.title
																																						}
																																					</div>
																																					<div
																																						dangerouslySetInnerHTML={{
																																							__html:
																																								data
																																									?.main_article
																																									.html_text,
																																						}}
																																						className="article-preview__text"
																																					/>
																																				</div>
																																			</Tooltip>
																																		</div>
																																	) : null}
																																</div>
																															</>
																														) : null}
																													</div>
																												</div>
																											);
																									  })()
																									: null}
																							</div>
																						</div>
																					</>
																				) : !error && !noResultsShown ? (
																					<>
																						<NoResults
																							txt={
																								variables.customFallbackMessage ??
																								""
																							}
																							protectionSystem={
																								variables.consumerFriendly ??
																								false
																							}
																						/>
																						{(noResultsShown = true)}
																					</>
																				) : null}
																			</div>
																			{data &&
																				Object.keys(data).length > 0 &&
																				data?.exact_file === null &&
																				data?.empty_answer &&
																				!conversationError &&
																				!noResultsShown(
																					<>
																						<NoResults
																							txt={
																								variables.customFallbackMessage ??
																								""
																							}
																							protectionSystem={
																								variables.consumerFriendly ??
																								false
																							}
																						/>
																						{(noResultsShown = true)}
																					</>
																				)}
																			{conversationError && !noResultsShown && (
																				<>
																					<NoResults
																						txt={
																							variables.customFallbackMessage ??
																							""
																						}
																						protectionSystem={false}
																					/>
																					{(noResultsShown = true)}
																				</>
																			)}
																		</div>
																	);
																})}

                                {/* {fileError && (
																	<Alert
																		message={uiText?.alerts?.fileOpenError}
																		duration={5000} // Duration in milliseconds
																		close={() => setFileError(null)}
																	/>
																)} */}
                                {showProgressBar && (
                                  <div
                                    className="customer-question skeleton-question"
                                    style={{ marginTop: "24px" }}
                                  >
                                    <div className="customer-question__top">
                                      {uiText?.you}:
                                    </div>
                                    <div className="customer-question__middle">
                                      {userInput.problem}
                                    </div>
                                    {otherQuestionsEnabled &&
                                    userInput.device === "general" ? null : (
                                      <div className="customer-question__bottom">
                                        {formatDeviceName(userInput.device)}
                                      </div>
                                    )}
                                  </div>
                                )}
                                {showProgressBar && (
                                  <div className="feedback-instructions">
                                    <Skeleton
                                      mainColor={mainColor}
                                      avatarImg={variables.avatarImg}
                                      avatarName={variables.avatarName}
                                      children={
                                        <ProgressBar
                                          progress={progress}
                                          status={status}
                                          color={mainColor}
                                          showBar={false}
                                          showAnimation={false}
                                        />
                                      }
                                    />
                                  </div>
                                )}
                                {feedback === false ? (
                                  <div
                                    className={
                                      variables.customFallbackMessage
                                        ? ""
                                        : "no-results__container"
                                    }
                                  >
                                    <div className="feedback-documents__col">
                                      <div
                                        className="assistant-avatar"
                                        style={{ paddingBottom: "1rem" }}
                                      >
                                        {variables?.avatarImg ? (
                                          <img
                                            src={variables.avatarImg}
                                            alt="avatar-img"
                                            style={{
                                              maxWidth: "40px",
                                              maxHeight: "40px",
                                            }}
                                          />
                                        ) : (
                                          <SAicon
                                            circleColor={mainColor}
                                            color={
                                              getBrightness(
                                                mainColor
                                              ) > 155
                                                ? "black"
                                                : "white"
                                            }
                                          />
                                        )}

                                        <span>
                                          {variables.avatarName ??
                                            "Service Assistant AI"}
                                        </span>
                                      </div>
                                      <NoResults
                                        txt={
                                          variables.customFallbackMessage ?? ""
                                        }
                                        protectionSystem={false}
                                      />
                                    </div>
                                  </div>
                                ) : null}
                                {showData &&
                                conversation.length > 0 &&
                                conversation.some(checkIfFeedbackIsNeeded) &&
                                (typeof feedback !== "boolean" || feedback) ? (
                                  <ConversationFeedback
                                    backgroundColor={mainColor}
                                    // color={
                                    // 	getBrightness(mainColor) > 155
                                    // 		? "black"
                                    // 		: "white"
                                    // }
                                    borderColor={mainColor}
                                    onClick={onConversationFeedbackSubmit}
                                    conversationId={conversationId}
                                    resetFeedback={resetFeedback}
                                    loading={loading}
                                    onBackButtonClick={onBackButtonClick}
                                    avatarImg={variables.avatarImg}
                                    avatarName={variables.avatarName}
                                  />
                                ) : null}
                              </>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className={fileLoading ? "modal visible" : "modal"}>
									<div className="modal-content">
										<span>{uiText?.form?.loadingFile}</span>
									</div>
								</div> */}
              </div>
              <div className="customer conversational chatbot-content__bottom">
                {hideForm ? (
                  <div
                    className="input-container flex"
                    style={{
                      position: "sticky",
                      bottom: "1rem",
                      marginTop: "auto",
                    }}
                  >
                    <AutoResizeTextarea
                      // rows={8}
                      placeholder={
                        recording ||
                        isSpinnerVisible ||
                        feedbackDisabled ||
                        loading
                          ? ""
                          : transcription === uiText?.form?.noTranscript
                          ? uiText?.form?.noTranscriptMessage
                          : uiText?.form?.followUpPlaceholder
                      }
                      value={loading ? "" : userInput.problem || ""}
                      onChange={(e) =>
                        setUserInput({
                          ...userInput,
                          problem: e.target.value,
                        })
                      }
                      color={mainColor}
                      useDefaultColor={useDefaultColor}
                      idForArea={"follow-up"}
                      isDisabled={
                        feedbackDisabled ||
                        loading ||
                        isSpinnerVisible ||
                        recording
                      }
                    />
                    {!recording && (
                      <div className="right-side">
                        <div
                          className={
                            isSpinnerVisible || feedbackDisabled || loading
                              ? "microphone disabled"
                              : "microphone"
                          }
                          onClick={
                            isSpinnerVisible || feedbackDisabled || loading
                              ? null
                              : startRecording
                          }
                          style={{ backgroundColor: "white" }}
                        >
                          <MicrophoneIcon color="#B3CADD" />
                        </div>
                        <button
                          type="submit"
                          onClick={() => {
                            handleSubmit();
                          }}
                          className={
                            (otherQuestionsEnabled &&
                              (userInput.problem === "" || loading)) ||
                            (!otherQuestionsEnabled &&
                              (userInput.device === "" ||
                                userInput.problem === "" ||
                                !variables?.aiTrained))
                              ? "submit-btn disabled"
                              : "submit-btn"
                          }
                          disabled={
                            (otherQuestionsEnabled &&
                              (userInput.problem === "" || loading)) ||
                            (!otherQuestionsEnabled &&
                              (userInput.device === "" ||
                                userInput.problem === "" ||
                                !variables?.aiTrained)) ||
                            feedbackDisabled
                          }
                          style={{
                            backgroundColor: mainColor,
                            color:
                              getBrightness(mainColor) > 155
                                ? "black"
                                : "white",
                            border: `1px solid ${mainColor}`,
                            width: "32px",
                            height: "32px",
                            padding: "8px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <ArrowRightIcon
                            color={
                              getBrightness(mainColor) > 155
                                ? "#000000"
                                : "#ffffff"
                            }
                          />
                        </button>
                      </div>
                    )}
                    {isSpinnerVisible && (
                      <div
                        style={{
                          position: "relative",
                          marginInline: "auto",
                          top: "16px",
                        }}
                      >
                        <div className="spnr"></div>
                      </div>
                    )}
                    {recording && (
                      <div
                        style={{ position: "relative", marginInline: "auto" }}
                      >
                        <VoiceAnimation />
                        <div className="stop-recording__container">
                          <div
                            className="stop-recording__background"
                            style={{ backgroundColor: mainColor }}
                          ></div>
                          <div
                            className="stop-recording"
                            onClick={stopRecording}
                            style={{ backgroundColor: mainColor }}
                          >
                            <span
                              style={{
                                borderColor:
                                  getBrightness(mainColor) > 155
                                    ? "#000000"
                                    : "#ffffff",
                              }}
                            ></span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div
                    className="input-container flex"
                    style={{
                      position: "sticky",
                      bottom: "1rem",
                      marginTop: "auto",
                    }}
                  >
                    <div
                      className={`input-container flex ${
                        otherQuestionsEnabled && inputWithOtherQuestionsClass
                      }`}
                      style={{
                        width: "100%",
                        maxWidth: " 849px",
                        marginInline: "auto",
                      }}
                    >
                      <AutoResizeTextarea
                        // rows={8}
                        placeholder={
                          recording || isSpinnerVisible
                            ? ""
                            : transcription === uiText?.form?.noTranscript
                            ? uiText?.form?.noTranscriptMessage
                            : uiText?.form?.questionPlaceholder
                        }
                        value={userInput.problem || ""}
                        onChange={(e) =>
                          setUserInput({
                            ...userInput,
                            problem: e.target.value,
                          })
                        }
                        color={mainColor}
                        useDefaultColor={useDefaultColor}
                      />
                      {!recording && (
                        <div className="right-side">
                          <div
                            className={
                              isSpinnerVisible
                                ? "microphone disabled"
                                : "microphone"
                            }
                            // onClick={isSpinnerVisible ? null : startRecording}
                            onClick={null}
                            style={{ backgroundColor: "white", cursor: "auto" }}
                          >
                            <MicrophoneIcon color="#B3CADD" />
                          </div>
                          <button
                            type="submit"
                            onClick={() => {
                              handleSubmit();
                            }}
                            className={
                              userInput.problem === "" ||
                              (!otherQuestionsEnabled &&
                                (userInput.device === "" ||
                                  userInput.problem === "" ||
                                  !variables?.aiTrained))
                                ? "submit-btn disabled"
                                : "submit-btn"
                            }
                            disabled={
                              (otherQuestionsEnabled &&
                                userInput.problem === "") ||
                              (!otherQuestionsEnabled &&
                                (userInput.device === "" ||
                                  userInput.problem === "" ||
                                  !variables?.aiTrained))
                            }
                            style={{
                              backgroundColor: mainColor,
                              color:
                                getBrightness(mainColor) > 155
                                  ? "black"
                                  : "white",
                              border: `1px solid ${mainColor}`,
                              width: "32px",
                              height: "32px",
                              padding: "8px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <ArrowRightIcon
                              color={
                                getBrightness(mainColor) > 155
                                  ? "#000000"
                                  : "#ffffff"
                              }
                            />
                          </button>
                        </div>
                      )}
                    </div>
                    {isSpinnerVisible && (
                      <div
                        style={{
                          position: "relative",
                          marginInline: "auto",
                          top: "16px",
                        }}
                      >
                        <div className="spnr"></div>
                      </div>
                    )}
                    {recording && (
                      <div
                        style={{
                          position: "relative",
                          marginInline: "auto",
                        }}
                      >
                        <VoiceAnimation />
                        <div className="stop-recording__container">
                          <div
                            className="stop-recording__background"
                            style={{
                              backgroundColor: mainColor,
                            }}
                          ></div>
                          <div
                            className="stop-recording"
                            onClick={stopRecording}
                            style={{
                              backgroundColor: mainColor,
                            }}
                          >
                            <span
                              style={{
                                borderColor:
                                  getBrightness(mainColor) > 155
                                    ? "#000000"
                                    : "#ffffff",
                              }}
                            ></span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          ) : null}
          <div
            className={`chatbot-btn ${isAnimating ? "animating" : ""} ${
              chatOpen ? "open" : "close"
            }`}
            style={{ background: useDefaultColor ? "linear-gradient(270deg, #626FFF 0%, #9040FE 64.5%)" : mainColor }}
            onClick={handleChatBtnClick}
          >
            <div className="chatbot-icon chatbot-icon-close">
              <CloseIcon
                color={
                  getBrightness(mainColor) > 155
                    ? "#000000"
                    : "#ffffff"
                }
              />
            </div>

            <div className="chatbot-icon chatbot-icon-chat">
              <ChatIcon
                color={
                  getBrightness(mainColor) > 155
                    ? "#000000"
                    : "#ffffff"
                }
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default FormContainer;
